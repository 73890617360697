const stage="production";

const gatewayURLs={
  dan: "https://2hxl6suv4g.execute-api.eu-west-2.amazonaws.com/dan",
  dev: "https://l9cj189rh5.execute-api.eu-west-2.amazonaws.com/dev",
  test: "https://a6a56ya2q5.execute-api.eu-west-2.amazonaws.com/test",
  training: "https://zk8tyuqqo6.execute-api.eu-west-2.amazonaws.com/training", 
  production: "https://s4jz7vx0i7.execute-api.eu-west-2.amazonaws.com/production", 
  conradtest: "https://99ku1jzf2g.execute-api.eu-west-2.amazonaws.com/conradtest",
  omnitest: "https://ojnbrbzh1d.execute-api.eu-west-2.amazonaws.com/omnitest",
  omniprod: "https://hb060r1nsi.execute-api.eu-west-2.amazonaws.com/omniprod", 
  conradprod: "https://4lb4uqm5e6.execute-api.eu-west-2.amazonaws.com/conradprod", 
  conraduat: "https://jd1gle6x9k.execute-api.eu-west-2.amazonaws.com/conraduat",
  omniuat: "https://80dev0efwi.execute-api.eu-west-2.amazonaws.com/omniuat", 
  demo: "https://503ektqy3d.execute-api.eu-west-2.amazonaws.com/demo", 
  unifytest: "https://97n2vwpql3.execute-api.eu-west-2.amazonaws.com/unifytest", 
  unifyprod: "https://1zp1hhvlrc.execute-api.eu-west-2.amazonaws.com/unifyprod", 
  unifyuat: "https://tm7m1cyw7l.execute-api.eu-west-2.amazonaws.com/unifyuat", 
  csstest: "https://7wg9s5ewrd.execute-api.eu-west-2.amazonaws.com/csstest", 
  csstestvalda: "https://07nvazlwh6.execute-api.eu-west-2.amazonaws.com/csstestvalda",
  greentest: "https://b0mkf8ovk5.execute-api.eu-west-2.amazonaws.com/greentest",
  greenuat: "https://5844df1t42.execute-api.eu-west-2.amazonaws.com/greenuat",
  greenprod: "https://ruajhxwtd8.execute-api.eu-west-2.amazonaws.com/greenprod", 
  norikertest: "https://i5orehffo5.execute-api.eu-west-2.amazonaws.com/norikertest",
  norikerprod: "https://hx9m0gpv83.execute-api.eu-west-2.amazonaws.com/norikerprod", 
  sandbox1: "https://j86frh9gdb.execute-api.eu-west-2.amazonaws.com/sandbox1", 
  sandbox2: "https://aspnhj8qb3.execute-api.eu-west-2.amazonaws.com/sandbox2"
};

const billURLs={
  dan: "https://4z9c4e3qsf.execute-api.eu-west-2.amazonaws.com/devmajor",
  dev: "https://n7shjzjhp1.execute-api.eu-west-2.amazonaws.com/dev",
  test: "https://ma006y4e03.execute-api.eu-west-2.amazonaws.com/test",
  training: "https://299fy528pk.execute-api.eu-west-2.amazonaws.com/training", 
  production: "https://y5d8016hyl.execute-api.eu-west-2.amazonaws.com/production", 
  conradtest: "https://i387ad1ty0.execute-api.eu-west-2.amazonaws.com/conradtest", 
  omnitest: "https://k9qsbwfzol.execute-api.eu-west-2.amazonaws.com/omnitest",
  omniprod: "https://bjylozft4d.execute-api.eu-west-2.amazonaws.com/omniprod", 
  conradprod: "", 
  conraduat: "", 
  omniuat: "https://m21qkl11n3.execute-api.eu-west-2.amazonaws.com/omniuat", 
  demo: "https://5m8h79ol3a.execute-api.eu-west-2.amazonaws.com/demo", 
  unifytest: "https://x6mc4mhmkl.execute-api.eu-west-2.amazonaws.com/unifytest", 
  unifyprod: "https://wgmok59t8a.execute-api.eu-west-2.amazonaws.com/unifyprod", 
  unifyuat: "", 
  csstest: "", 
  csstestvalda: "", 
  greentest: "",
  greenuat: "",
  greenprod: "", 
  norikertest: "https://sj4lvhr477.execute-api.eu-west-2.amazonaws.com/norikertest",
  norikerprod: "", 
  sandbox1: "", 
  sandbox2: ""
  
};

const commsURLs={
  dan: "https://v1ujqx2698.execute-api.eu-west-2.amazonaws.com/dev",
  dev: "https://v1ujqx2698.execute-api.eu-west-2.amazonaws.com/dev/",
  test: "https://akznb8tkt4.execute-api.eu-west-2.amazonaws.com/test",
  training: "https://ia2zwspbp9.execute-api.eu-west-2.amazonaws.com/training", 
  production: "https://9cl37kucj9.execute-api.eu-west-2.amazonaws.com/production", 
  conradtest: "", 
  omnitest: "https://r1kzce7sc6.execute-api.eu-west-2.amazonaws.com/omnitest",
  omniprod: "https://zoehhjytxi.execute-api.eu-west-2.amazonaws.com/omniprod", 
  conradprod: "", 
  conraduat: "", 
  omniuat: "https://1zdrl8g461.execute-api.eu-west-2.amazonaws.com/omniuat", 
  demo: "https://akznb8tkt4.execute-api.eu-west-2.amazonaws.com/test", 
  unifytest: "https://zd1tt8js7g.execute-api.eu-west-2.amazonaws.com/unifytest", 
  unifyprod: "https://o6igepl96d.execute-api.eu-west-2.amazonaws.com/unifyprod", 
  unifyuat: "", 
  csstest: "", 
  csstestvalda: "", 
  greentest: "",
  greenuat: "",
  greenprod: "" , 
  norikertest: "https://n0aoyjexdi.execute-api.eu-west-2.amazonaws.com/norikertest",
  norikerprod: "", 
  sandbox1: "", 
  sandbox2: ""
};

const billFrontEndURLs={
  dan: "",
  dev: "",
  test: "https://test.sgbill.seaglasscloud.com",
  training: "https://sgbilltraining.valda.seaglasscloud.com", 
  production: "https://sgbill.valda.seaglasscloud.com", 
  conradtest: "https://conradtest.sgbill.seaglasscloud.com", 
  omnitest: "https://omnitest.sgbill.seaglasscloud.com",
  omniprod: "https://omni.sgbill.seaglasscloud.com", 
  conradprod: "https://conrad.sgbill.seaglasscloud.com", 
  conraduat: "https://conraduat.sgbill.seaglasscloud.com", 
  omniuat: "https://omniuat.sgbill.seaglasscloud.com", 
  demo: "", 
  unifytest: "https://unifytest.sgbill.seaglasscloud.com", 
  unifyprod: "https://unify.sgbill.seaglasscloud.com", 
  unifyuat: "https://unifyuat.sgbill.seaglasscloud.com", 
  csstest: "", 
  csstestvalda: "",
  greentest: "",
  greenuat: "",
  greenprod: "", 
  norikertest: "https://norikertest.sgbill.seaglasscloud.com",
  norikerprod: "https://noriker.sgbill.seaglasscloud.com", 
  sandbox1: "https://sandbox1.sgbill.seaglasscloud.com", 
  sandbox2: "https://sandbox2.sgbill.seaglasscloud.com"
  
};

const userPoolIds={
  dan: "eu-west-2_kWyJkAJXK",
  dev: "eu-west-2_kWyJkAJXK",
  test: "eu-west-2_kWyJkAJXK",
  smarttest: "eu-west-2_kWyJkAJXK",
  training: "eu-west-2_U1hd34rO5", 
  production: "eu-west-2_mV9Lj7t2T", 
  conradtest: "eu-west-2_UdlHufq9g", 
  omnitest: "eu-west-2_os0xm7TOW", 
  omniprod: "eu-west-2_fhFeoUNzn", 
  conradprod: "eu-west-2_P2aotLbqM", 
  conraduat: "eu-west-2_QKJOdLZzO", 
  omniuat: "eu-west-2_zAqlPDDJT", 
  unifytest: "eu-west-2_tX4LJ5va9", 
  unifyprod: "eu-west-2_ej8IDWRbr", 
  unifyuat: "eu-west-2_BA03U0sor", 
  demo: "eu-west-2_VATSUpeDZ", 
  csstest: "eu-west-2_2bYvPgn85", 
  csstestvalda: "eu-west-2_vQZTY5yEr", 
  greentest: "eu-west-2_943DXPRqH",
  greenuat: "eu-west-2_lgtw9bKqg",
  greenprod: "eu-west-2_wRAtDQY4t", 
  norikertest: "eu-west-2_RuQVJtTGD",
  norikerprod: "eu-west-2_U5B4Yyvhh", 
  sandbox1: "eu-west-2_Bq2ALLZWE", 
  sandbox2: "eu-west-2_1CIpUWgDC"
};

const appClientIds={
  dev: "lj9rvmobt48m2lg124j5d0sho",
  dan: "lj9rvmobt48m2lg124j5d0sho",
  test: "lj9rvmobt48m2lg124j5d0sho",
  smarttest: "lj9rvmobt48m2lg124j5d0sho",
  training: "1a05jst02r67oqs779mobt3v4c", 
  production: "4hbtasb3doa2mc2nckj1u9s11e", 
  conradtest: "73ctl2u62rbqsrm4unqsivfb4a", 
  omnitest: "7iafrragmdiunn0koilht53hsn", 
  omniprod: "2pahv5qp6evvhbpfi8gjqmpoqr", 
  conradprod: "65sqrfppv4g2kmk4st9qgcndkj",
  conraduat: "3raes5msi5nlpe05ihsngh0oto", 
  omniuat: "1afasibmi2jcqtu1ieh1k0cmmm", 
  demo: "7lqfrtugkbesjplo04220eqf0t",
  unifytest: "3r0afvjqvllru9rei8nkbc92h4", 
  unifyprod: "7qn1mqnfq8plr26gh61ts695ea", 
  unifyuat: "15u68ijj49cmaq6037u4sj10lf", 
  csstest: "3qc8cieen9e1l58qlmus59sqdu", 
  csstestvalda: "3340dqqmg8pbqi1athuo39uj44", 
  greentest: "5vefeneffb5fub5hj8rmop1omr",
  greenuat: "dnf15spvt5tdrfvcvtkt3717n",
  greenprod: "552es7uqgco9d54r6ufqh6v2fd", 
  norikertest: "8j6b5orl6r03gk82hf91j17gt",
  norikerprod: "5cc074r0a37vr8q0tgrq4kjv9o", 
  sandbox1: "4ijt3ljit0meu0mue9gnld8lh1", 
  sandbox2: "785dhj3n5jkqtivau0rqtuhlpi"
};

const awsEnv={
dev: "sg",
dan: "sg",
test: "sg",
training: "valda",
production: "valda", 
conradtest: "sg", 
omnitest: "sg", 
omniprod: "sg", 
conradprod: "sg", 
conraduat: "sg", 
omniuat: "sg", 
demo: "sg", 
unifytest: "sg", 
unifyprod: "sg", 
unifyuat: "sg", 
csstest: "sg", 
csstestvalda: "valda", 
greentest: "sg",
greenuat: "sg",
greenprod: "sg", 
norikertest: "sg",
norikerprod: "sg", 
sandbox1: "sg", 
sandbox2: "sg"
};

const supplierStages={
  dev: "Valda Energy (Dev)",
  dan: "Valda Energy (Dan)",
  test: "Valda Energy (Test)",
  training: "Valda Energy (UAT)",
  production: "Valda Energy", 
  conradtest: "Conrad Energy (Test)", 
  conradprod: "Conrad Energy", 
  conraduat: "Conrad Energy (UAT)", 
  demo: "Demo", 
  unifytest: "Unify Energy (Test)", 
  unifyprod: "Unify Energy", 
  unifyuat: "Unify Energy (UAT)", 
  csstest: "Omni Energy (CSS Test)", 
  csstestvalda: "Valda Energy (CSS Test)", 
  greentest: "100Green (Test)",
  greenuat: "100Green (UAT)",
  greenprod: "100Green", 
  norikertest: "Noriker Energy (Test)",
  norikerprod: "Noriker Energy", 
  sandbox1: "Sandbox 1", 
  sandbox2: "Sandbox 2"
  };

const identityPoolIds={
  dev: "eu-west-2:4f8b16cb-7a2e-4f44-a1f1-d657b4a494b9",
  dan: "eu-west-2:4f8b16cb-7a2e-4f44-a1f1-d657b4a494b9",
  test: "eu-west-2:4f8b16cb-7a2e-4f44-a1f1-d657b4a494b9",
  smarttest: "eu-west-2:4f8b16cb-7a2e-4f44-a1f1-d657b4a494b9",
  training: "eu-west-2:9a93047c-21f6-4d16-a4dd-a0e9b3abe967", 
  production: "eu-west-2:af490153-bdd7-4d34-96d8-9e437f34d62f", 
  conradtest: "eu-west-2:68af4497-ae15-47ac-aa88-5dc352edef45", 
  omnitest: "eu-west-2:e659a7bf-b8fc-4956-b230-dcf9cee88e51", 
  omniprod: "eu-west-2:ebd3661d-8600-41f3-a2c6-0cdcf1f59050", 
  conradprod: "eu-west-2:33d3c1c0-4ed7-45a2-8ddf-e4f509584b27",
  conraduat: "eu-west-2:1c36e85b-4f0d-4afa-a775-4a59d4891368", 
  omniuat: "eu-west-2:c409fa15-e20a-4427-9d24-90a6adf16140", 
  demo: "eu-west-2:0be22033-6661-4bf7-ba7a-b50c9eebc6dd", 
  unifytest: "eu-west-2:e6dbb588-24af-4092-8a00-f0c7f9b17e80", 
  unifyprod: "eu-west-2:a54034ad-f880-4a2f-a440-241ae9f0e0e0", 
  unifyuat: "eu-west-2:6d857722-aae9-44b2-ad51-b2990d4d85d8", 
  csstest: "eu-west-2:67705e1e-7a9c-4254-84a2-2dee3250a0bd", 
  csstestvalda: "eu-west-2:b78ab149-6d48-471d-9e81-76b90c23a728",
  greentest: "eu-west-2:c9fcff0f-13dc-47fd-b880-ef7fafb07c43",
  greenuat: "eu-west-2:a080f614-da87-4a73-a96c-d299b28c1c63",
  greenprod: "eu-west-2:2e67f195-3ee9-4b13-baa0-3e28e6a9ea5d", 
  norikertest: "eu-west-2:2a8df9dc-deb3-4dba-bc05-45dc13264922",
  norikerprod: "eu-west-2:e7bc9e29-b9de-4f33-8854-010e92d31338", 
  sandbox1: "eu-west-2:8aaf04c2-5a54-48cb-a9fc-493b13259ca6", 
  sandbox2: "eu-west-2:b6d1a3ee-b910-45fd-a0ee-7ff2610a640a"
};

const getAddressAPIKeys={
  dev: "9IAFrcnkskGk5MaEPdp5Lw20461",
  dan: "9IAFrcnkskGk5MaEPdp5Lw20461",
  test: "9IAFrcnkskGk5MaEPdp5Lw20461",
  training: "9IAFrcnkskGk5MaEPdp5Lw20461",
  production: "9IAFrcnkskGk5MaEPdp5Lw20461", 
  conradtest: "9IAFrcnkskGk5MaEPdp5Lw20461", 
  omnitest: "yg61qYqEQkyVAkUywQ7FBA27885", 
  omniprod: "7_Ouw8IzXEGgyFoLSvC4og28461", 
  conradprod: "", 
  conraduat: "", 
  omniuat: "yg61qYqEQkyVAkUywQ7FBA27885", 
  demo: "9IAFrcnkskGk5MaEPdp5Lw20461", 
  unifytest: "pe74SsECtE-NnARCtBso2w32914", 
  unifyprod: "pe74SsECtE-NnARCtBso2w32914", 
  unifyuat: "pe74SsECtE-NnARCtBso2w32914", 
  greentest: "pe74SsECtE-NnARCtBso2w32914",
  greenuat: "pe74SsECtE-NnARCtBso2w32914",
  greenprod: "pe74SsECtE-NnARCtBso2w32914", 
  norikertest: "pe74SsECtE-NnARCtBso2w32914",
  norikerprod: "pe74SsECtE-NnARCtBso2w32914", 
  sandbox1: "pe74SsECtE-NnARCtBso2w32914", 
  sandbox2: "pe74SsECtE-NnARCtBso2w32914"
}

const cookieURLs={
    training: "https://www.valdaenergy.com/cookie-policy",
    production: "https://www.valdaenergy.com/cookie-policy", 
  }
  
const eSignStages=["unifyuat", "unifytest", "unifyprod"]

export default {
    mfa: false, 
    stage: stage, 
    applicationid: 2, 
    s3uploadbucket: 'seaglass.'+stage+'.comms.upload', 
    s3documentsbucket: stage+'.sgsign.downloads', 
    s3uploadfolder: 'sgInteract', 
    aws: {
      // accessKeyId: accessKeyIds[stage],
      // secretAccessKey: secretAccessKeys[stage], 
      region: 'eu-west-2'
    },     
    cognito: {
      USER_POOL_ID: userPoolIds[stage],
      APP_CLIENT_ID: appClientIds[stage], 
      REGION: "eu-west-2",
      IDENTITY_POOL_ID: identityPoolIds[stage],
    },
    getAddress: {
      URL: "api.getAddress.io", 
      APIKey: getAddressAPIKeys[stage]
    },     
    apiGateway: {URL: gatewayURLs[stage], REGION: "eu-west-2"},
    cookieURL: cookieURLs[stage],
    eSign: (eSignStages.indexOf(stage)>-1)
  };
